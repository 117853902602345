import { template as template_906f4126a8344fddb8021f873344a5fe } from "@ember/template-compiler";
const FKText = template_906f4126a8344fddb8021f873344a5fe(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
