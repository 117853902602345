import { template as template_136c62330fa2400ba6d011a7ec7f3801 } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_136c62330fa2400ba6d011a7ec7f3801(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
